<template>
    <div>
        <div class="header">
            <span class="header_txt">店铺设置</span>
        </div>
        <div class="content">
            <Form>
                <FormItem label="经营设置：">
                    <span>设置您店铺的经营状态</span>
                </FormItem>
                <FormItem label="经营状态：">
                    <RadioGroup v-model="disabledGroup">
                        <Radio label="营业"></Radio>
                        <Radio label="休息"></Radio>
                        <p>设置为休息后，用户将无法在店铺中进行购买操作，请谨情操作</p>
                    </RadioGroup>
                </FormItem>
                <FormItem label="营业时间：">
                    <RadioGroup v-model="disabledGroupTime">
                        <Radio label="全天"></Radio>
                        <Radio label="自定义"></Radio>
                        <p>用户只能在您设置的营业时间之内，进行购买操作</p>
                    </RadioGroup>
                </FormItem>
            </Form>
        </div>
        <div class="header">
            <span class="header_txt">交易设置</span>
        </div>
        <div class="content">
            <Form>
                <FormItem label="订单设置"></FormItem>
                <FormItem label="待付款订单取消时间：">
                    <span>拍下订单<InputNumber :max="10" :min="0" v-model="order"></InputNumber>分钟未付款自动取消订单</span>
                </FormItem>
                <FormItem label="发货后自动确认收货时间：">
                    <span>发货后<InputNumber :max="30" :min="0" v-model="date"></InputNumber>天自动确认收货</span>
                </FormItem>
            </Form>
        </div>
        <div class="header">
            <span class="header_txt">售后管理设置</span>
        </div>
        <div class="content">
            <div>开启/关闭售后按钮</div>
            <div>
                <RadioGroup v-model="afterGroup">
                    <Radio label="开启"></Radio>
                    <Radio label="关闭"></Radio>
                </RadioGroup> 
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                disabledGroup: '营业',
                disabledGroupTime: '全天',
                order:0,
                date:15,
                afterGroup:'关闭'
            }
        },
        methods: {
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.content{
    margin: 20px 0;
}
</style>